import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Calendar } from "react-date-range"
import { enUS } from "react-date-range/dist/locale"

import check from "../../assets/images/check.svg"
import { getApiHelper, postApiHelper } from "../../util/apiHelpers"
import {
  changeDateFormatUS,
  changeDateFormatBackend,
} from "../../util/changeFormat"
import WrongMessage from "../Fields/WrongMessage"

const getSpinner = () => {
  return (
    <svg
      role="status"
      className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#586283]"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  )
}

const AUDIENCE_AND_CAMPAIGN = 0
const AUDIENCE_ONLY = 1
const CAMPAIGN_ONLY = 2

function CampaignModal({ closeModal, filtersValues, dealerId, sql }) {
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState("")
  const [newCampaign, setNewCampaign] = useState(true)
  const [loadingCampaign, setLoadingCampaign] = useState(false)
  const [audienceCampaign, setAudienceCampaign] = useState(
    AUDIENCE_AND_CAMPAIGN
  )
  const [campaignSelected, setCampaignSelected] = useState()
  const [campaigns, setCampaigns] = useState([])
  const [campaignObjectives, setCampaignObjectives] = useState([])
  const [campaignOptimizationGoals, setCampaignOptimizationGoals] = useState([])
  const [campaignBudgetTypes, setCampaignBudgetTypes] = useState([])
  const [campaign, setCampaign] = useState({
    name: "",
    objective: "",
    status: "ACTIVE",
    startDate: null,
    endDate: null,
    budget: "",
    budgetType: "lifetime",
    optimizationGoal: "",
  })
  const [showDates, setShowDates] = useState({
    startDate: false,
    endDate: false,
  })
  const [loading, setLoading] = useState(false)

  const handleChange = (event) => {
    const { name, value } = event.target
    let formattedValue = value

    if (name === "budget") {
      formattedValue = Number(value.replace(/[^0-9.-]+/g, ""))
      formattedValue =
        "$" +
        formattedValue.toLocaleString("en-US", {
          style: "decimal",
          currency: "USD",
        })
    }
    setCampaign({
      ...campaign,
      [name]: formattedValue,
    })
  }

  const handleSuccess = () => {
    setSuccess(true)
    setLoading(false)
    setTimeout(() => navigate("/target-market/campaigns"), 5000)
  }

  const handleSubmit = () => {
    if (loading) return null
    setLoading(true)
    if (newCampaign) {
      postApiHelper("campaigns/create", {
        campaignName: campaign.name,
        campaignObjective: campaign.objective,
        status: campaign.status,
        startDateTime: changeDateFormatBackend(campaign.startDate),
        endDateTime: changeDateFormatBackend(campaign.endDate),
        budget: campaign.budget,
        budgetType: campaign.budgetType,
        optimizationGoal: campaign.optimizationGoal,
        dealerID: `${dealerId}`,
        sqlService: filtersValues.excludeService
          ? `${filtersValues.excludeService}`
          : "",
        sqlSales: filtersValues.excludeSales
          ? `${filtersValues.excludeSales}`
          : "",
        sql,
      }).then((response) => {
        if (response.status !== 200) {
          if (response?.response?.data?.error) {
            const error = response?.response?.data?.error
            setMessage(`${error.message}: ${error.error_user_msg}`)
          } else {
            console.error("error", response)
            setMessage(
              "An unexpected error occurred while saving the campaign."
            )
          }
          setTimeout(() => setMessage(""), 8000)
          setLoading(false)
          return
        }
        handleSuccess()
      })
    } else {
      let campaignsUrl = `campaigns/${campaignSelected}`
      if (audienceCampaign === AUDIENCE_ONLY) {
        campaignsUrl = `audiences/${campaignSelected}`
      }
      postApiHelper(campaignsUrl, {
        campaignName: campaign.name,
        campaignObjective: campaign.objective,
        startDateTime: changeDateFormatUS(campaign.startDate),
        endDateTime: changeDateFormatUS(campaign.endDate),
        budget: campaign.budget,
        budgetType: campaign.budgetType,
        optimizationGoal: campaign.optimizationGoal,
        sqlService:
          audienceCampaign !== CAMPAIGN_ONLY && filtersValues.excludeService
            ? `${filtersValues.excludeService}`
            : "",
        sqlSales:
          audienceCampaign !== CAMPAIGN_ONLY && filtersValues.excludeSales
            ? `${filtersValues.excludeSales}`
            : "",
        sql: audienceCampaign !== CAMPAIGN_ONLY ? sql : "",
      }).then((response) => {
        if (response.status !== 200) {
          if (response?.response?.data?.error) {
            const error = response?.response?.data?.error
            setMessage(`${error.message}: ${error.error_user_msg}`)
          } else {
            console.error("error", response)
            setMessage(
              "An unexpected error occurred while saving the campaign."
            )
          }
          setTimeout(() => setMessage(""), 8000)
          setLoading(false)
          return
        }
        handleSuccess()
      })
    }
  }

  useEffect(() => {
    setLoadingCampaign(true)
    getApiHelper(`campaigns?dealerID=${dealerId}`).then((response) => {
      if (response.status !== 200) {
        console.error("error", response)
        return
      }
      setCampaigns(response.data.data)
      setLoadingCampaign(false)
    })
    getApiHelper(`fbapi/info/campaignObjectives`).then((response) => {
      if (response.status !== 200) {
        console.error("error", response)
        return
      }
      setCampaignObjectives(response.data)
    })
    getApiHelper(`fbapi/info/optimizationGoals`).then((response) => {
      if (response.status !== 200) {
        console.error("error", response)
        return
      }
      setCampaignOptimizationGoals(response.data)
    })
    getApiHelper(`fbapi/info/budgetTypes`).then((response) => {
      if (response.status !== 200) {
        console.error("error", response)
        return
      }
      setCampaignBudgetTypes(response.data)
    })
  }, [])

  useEffect(() => {
    if (campaignSelected) {
      let campaignItem = campaigns.filter(
        (campaignItem) => campaignItem.id === campaignSelected
      )
      if (!campaignItem.length) return
      campaignItem = campaignItem[0]
      setCampaign({
        id: campaignItem.id,
        name: campaignItem.name,
        objective: campaignItem.objective,
        status: campaignItem.status,
        startDate: new Date(campaignItem.created_time),
        endDate: new Date(campaignItem.stop_time),
        budget: campaignItem.budget,
        budgetType: campaignItem.budget_type,
        optimizationGoal: campaignItem.optimization_goal,
      })
    }
  }, [campaignSelected])

  const showFields = newCampaign || campaignSelected
  return (
    <div
      id="campaignModal"
      tabIndex="-1"
      aria-hidden="true"
      className={`overflow-y-auto backdrop-blur-sm overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-auto flex items-center justify-center`}
    >
      <div className="relative p-4 w-full max-w-5xl h-full md:h-auto">
        <div className="relative bg-white rounded-lg shadow">
          <div className="p-6 space-y-6">
            {success ? (
              <div className="flex w-full justify-center">
                <div className="bg-[#E57200] py-2 rounded-md flex justify-center mb-4 w-[550px]">
                  <img
                    className="mx-2 pt-[4px] h-[85%]"
                    src={check}
                    alt="check"
                  />
                  <h3 className="text-white font-bold text-[15px]">
                    The campaign was saved successfully.
                  </h3>
                </div>
              </div>
            ) : (
              ""
            )}
            {message && <WrongMessage message={message} />}
            <div className="h-[67px] flex flex-col items-center">
              <h2 className="font-bold text-[#586283] text-[20px]">
                Create or Edit a Campaign
              </h2>
              <div className="flex w-full items-center justify-center">
                <div className="flex flex-row bg-white rounded-lg w-[85%] h-[67px] items-center justify-center">
                  <div className="flex flex-row items-center justify-center w-[200px]">
                    <input
                      name="newCampaign"
                      onChange={() => setNewCampaign(true)}
                      checked={newCampaign}
                      type="checkbox"
                      className="w-4 h-4 text-[#298FC2] bg-[#298fc217] rounded-full border-gray-300 focus:ring-[#298FC2] focus:ring-2"
                    />
                    <h3 className="ml-[6px]">New Campaign</h3>
                  </div>
                  <div className="flex flex-row items-center justify-center w-[200px]">
                    <input
                      name="editCampaign"
                      onChange={() => setNewCampaign(false)}
                      checked={!newCampaign}
                      type="checkbox"
                      className="w-4 h-4 text-[#298FC2] bg-[#298fc217] rounded-full border-gray-300 focus:ring-[#298FC2] focus:ring-2"
                    />
                    <h3 className="ml-[6px]">Edit Campaign</h3>
                  </div>
                </div>
              </div>
            </div>
            {!newCampaign && (
              <>
                <div className="flex flex-row bg-white rounded-lg h-[67px] items-center justify-center">
                  <div className="flex flex-row items-center justify-center w-[200px]">
                    <input
                      name="audienceCampaign"
                      onChange={() =>
                        setAudienceCampaign(AUDIENCE_AND_CAMPAIGN)
                      }
                      checked={audienceCampaign === AUDIENCE_AND_CAMPAIGN}
                      type="checkbox"
                      className="w-4 h-4 text-[#298FC2] bg-[#298fc217] rounded-full border-gray-300 focus:ring-[#298FC2] focus:ring-2"
                    />
                    <h3 className="ml-[6px]">Audience & Campaign</h3>
                  </div>
                  <div className="flex flex-row items-center justify-center w-[200px]">
                    <input
                      name="audienceCampaign"
                      onChange={() => setAudienceCampaign(AUDIENCE_ONLY)}
                      checked={audienceCampaign === AUDIENCE_ONLY}
                      type="checkbox"
                      className="w-4 h-4 text-[#298FC2] bg-[#298fc217] rounded-full border-gray-300 focus:ring-[#298FC2] focus:ring-2"
                    />
                    <h3 className="ml-[6px]">Audience Only</h3>
                  </div>
                  <div className="flex flex-row items-center justify-center w-[200px]">
                    <input
                      name="audienceCampaign"
                      onChange={() => setAudienceCampaign(CAMPAIGN_ONLY)}
                      checked={audienceCampaign === CAMPAIGN_ONLY}
                      type="checkbox"
                      className="w-4 h-4 text-[#298FC2] bg-[#298fc217] rounded-full border-gray-300 focus:ring-[#298FC2] focus:ring-2"
                    />
                    <h3 className="ml-[6px]">Campaign Only</h3>
                  </div>
                </div>
                <div className="grid grid-cols-3 w-full items-center justify-center">
                  <h3 className="col-span-1 font-bold text-[#586283] text-[16px] mb-1">
                    Campaigns
                  </h3>
                  {loadingCampaign ? (
                    getSpinner()
                  ) : (
                    <select
                      name="campaignSelected"
                      onChange={(event) =>
                        setCampaignSelected(event.target.value)
                      }
                      value={campaignSelected}
                      placeholder="Campaigns"
                      className="grid col-span-2 rounded-xl w-full px-[16px] py-[8px] focus:outline-[#58628325] ml-[15px] focus:border-none"
                    >
                      <option value="">Select a campaign</option>
                      {campaigns.map((campaignItem) => (
                        <option key={campaignItem.name} value={campaignItem.id}>
                          {campaignItem.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </>
            )}
            {showFields && (
              <>
                {(newCampaign || audienceCampaign !== CAMPAIGN_ONLY) && (
                  <div className="grid grid-cols-3 w-full items-center justify-center">
                    <h3 className="grid col-span-1 font-bold text-[#586283] text-[16px] mb-1">
                      Audience Name
                    </h3>
                    <input
                      name="name"
                      onChange={(event) => handleChange(event)}
                      value={campaign.name}
                      placeholder="Build Name"
                      className="grid col-span-2 rounded-xl w-full p-[16px] border-[1px] border-black focus:outline-[#58628325] ml-[15px]"
                    />
                  </div>
                )}
                {newCampaign && (
                  <>
                    <div className="grid grid-cols-3 w-full items-center justify-center">
                      <h3 className="col-span-1 font-bold text-[#586283] text-[16px] mb-1">
                        Objective
                      </h3>
                      <select
                        name="objective"
                        onChange={(event) => handleChange(event)}
                        value={campaign.objective}
                        placeholder="Objective"
                        className="grid col-span-2 rounded-xl w-full px-[16px] py-[8px] focus:outline-[#58628325] ml-[15px] focus:border-none"
                      >
                        {campaignObjectives.map((objective) => (
                          <option key={objective} value={objective}>
                            {objective}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="grid grid-cols-3 w-full items-center justify-center">
                      <h3 className="col-span-1 font-bold text-[#586283] text-[16px] mb-1">
                        Status
                      </h3>
                      <select
                        name="status"
                        onChange={(event) => handleChange(event)}
                        value={campaign.status}
                        placeholder="Satus"
                        disabled={true}
                        className="grid col-span-2 rounded-xl w-full px-[16px] py-[8px] focus:outline-[#58628325] ml-[15px] focus:border-none"
                      >
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="PAUSED">PAUSED</option>
                      </select>
                    </div>
                    <div className="grid grid-cols-3 w-full items-center justify-center">
                      <h3 className="col-span-1 font-bold text-[#586283] text-[16px] mb-1">
                        Budget Type
                      </h3>
                      <select
                        name="budgetType"
                        onChange={(event) => handleChange(event)}
                        value={campaign.budgetType}
                        placeholder="Budget Type"
                        className="grid col-span-2 rounded-xl w-full px-[16px] py-[8px] focus:outline-[#58628325] ml-[15px] focus:border-none"
                      >
                        {campaignBudgetTypes.map((budget) => (
                          <option key={budget} value={budget}>
                            {budget}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
                {(newCampaign || audienceCampaign !== AUDIENCE_ONLY) && (
                  <>
                    <div className="grid grid-cols-3 w-full items-center justify-center">
                      <h3 className="grid col-span-1 font-bold text-[#586283] text-[16px] mb-1">
                        Budget
                      </h3>
                      <input
                        name="budget"
                        onChange={(event) => handleChange(event)}
                        value={campaign.budget}
                        placeholder="Budget"
                        className="grid col-span-2 rounded-xl w-full p-[16px] border-[1px] border-black focus:outline-[#58628325] ml-[15px]"
                      />
                    </div>
                    <div className="grid grid-cols-3 w-full items-center justify-center">
                      <h3 className="col-span-1 font-bold text-[#586283] text-[16px] mb-1">
                        Optimization Goal
                      </h3>
                      <select
                        name="optimizationGoal"
                        onChange={(event) => handleChange(event)}
                        value={campaign.optimizationGoal}
                        placeholder="Optimization Goal"
                        className="grid col-span-2 rounded-xl w-full px-[16px] py-[8px] focus:outline-[#58628325] ml-[15px] focus:border-none"
                      >
                        {campaignOptimizationGoals.map((optimizationGoal) => (
                          <option
                            key={optimizationGoal}
                            value={optimizationGoal}
                          >
                            {optimizationGoal}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="grid grid-cols-4 gap-6 w-full items-center justify-center">
                      <h3 className="grid col-span-1 font-bold text-[#586283] text-[16px] mb-1">
                        Start Date
                      </h3>
                      <div
                        onClick={() =>
                          setShowDates({
                            endDate: false,
                            startDate: !showDates.startDate,
                          })
                        }
                        className="grid col-span-1 w-[125px] rounded-xl p-[16px] border-[1px] border-black focus:outline-[#58628325] ml-[15px]"
                      >
                        {changeDateFormatUS(campaign.startDate) || "MM/DD/YYYY"}
                      </div>
                      <h3 className="grid col-span-1 font-bold text-[#586283] text-[16px] mb-1">
                        End Date
                      </h3>
                      <div
                        onClick={() =>
                          setShowDates({
                            startDate: false,
                            endDate: !showDates.endDate,
                          })
                        }
                        className="grid col-span-1 w-[125px] rounded-xl p-[16px] border-[1px] border-black focus:outline-[#58628325] ml-[15px]"
                      >
                        {changeDateFormatUS(campaign.endDate) || "MM/DD/YYYY"}
                      </div>
                    </div>
                    <div className="grid grid-cols-4 w-full items-center justify-center">
                      {showDates.startDate && (
                        <>
                          <Calendar
                            onChange={(item) =>
                              handleChange({
                                target: { name: "startDate", value: item },
                              })
                            }
                            locale={enUS}
                            date={campaign.startDate}
                            className="grid col-span-2 rounded-xl p-[16px] focus:outline-[#58628325] ml-[15px]"
                          />
                          <div className="grid col-span-2"></div>
                        </>
                      )}
                      {showDates.endDate && (
                        <>
                          <div className="grid col-span-2"></div>
                          <Calendar
                            onChange={(item) =>
                              handleChange({
                                target: { name: "endDate", value: item },
                              })
                            }
                            locale={enUS}
                            date={campaign.endDate}
                            className="grid col-span-2 rounded-xl p-[16px] focus:outline-[#58628325] ml-[15px]"
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="flex items-center justify-center p-4 space-x-2 rounded-b border-t border-gray-200">
            <button
              type="button"
              onClick={handleSubmit}
              className="flex items-center w-[103px] justify-around text-white bg-[#298FC2] focus:ring-4 font-bold rounded-lg text-sm px-5 py-2.5 text-center mr-2"
            >
              {loading ? getSpinner() : "Submit"}
            </button>
            <button
              type="button"
              onClick={closeModal}
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignModal
